import { gql } from '~/__gql-generated__';
import {
  PostStatus,
  PostType,
  type PostWhere,
} from '~/__gql-generated__/graphql';
import { cache, createAsync, useSearchParams } from '@solidjs/router';
import { For, Show, type JSX } from 'solid-js';

import Pages from '~/components/Pages';
import PostLinkItem from '~/components/PostLinkItem';
import styles from '~/components/PostList.module.scss';
import rootStyles from '~/components/Root.module.scss';
import NotFound from '~/routes/[...404]';
import { client } from '~/utils/graphql';
import { renderFragment } from '~/utils/renderFragment';

const PER_PAGE = 20;

const POSTS = gql(`
  query Posts($where: PostWhere, $limit: Int!, $offset: Int!) {
    postsAggregate(where: $where) {
      count
    }
    posts(
      where: $where
      options: {
        limit: $limit
        offset: $offset
        sort: [{ sticky: DESC }, { publishedAt: DESC }]
      }
    ) {
      id
      slug
      revisionsConnection(where: { edge: { status: CURRENT } }) {
        edges {
          node {
            title
            lead
            sticky
            publishedAt
            cover {
              url
            }
            category {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
`);

const getPosts = cache(async (page: number, filter?: PostWhere) => {
  'use server';

  const { data } = await client.query({
    query: POSTS,
    variables: {
      where: {
        status: PostStatus.Published,
        type: PostType.Article,
        ...filter,
      },
      limit: PER_PAGE,
      offset: page * PER_PAGE,
    },
  });

  const count = data.postsAggregate.count;
  const posts = data.posts.map(post => {
    const revision = post.revisionsConnection.edges[0].node;

    return {
      slug: post.slug,
      title: renderFragment(JSON.parse(revision.title ?? '{}'), true),
      lead: renderFragment(JSON.parse(revision.lead ?? '{}'), true),
      sticky: revision.sticky,
      publishedAt: new Date(revision.publishedAt),
      category: revision.category,
      cover: revision.cover?.url ?? '',
    };
  });

  return { posts, count };
}, 'posts');

interface PostListProps {
  readonly filter?: PostWhere;
  readonly children?: JSX.Element;
}

export default function PostList(props: PostListProps) {
  const [parameters] = useSearchParams();
  const page = () =>
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    Math.max(0, Number.parseInt(parameters.p?.toString() || '1', 10) - 1);

  const data = createAsync(() => getPosts(page(), props.filter), {
    initialValue: { posts: [], count: -1 },
  });

  const maxPage = () => Math.ceil(data().count / PER_PAGE);

  return (
    <Show when={data().count} fallback={<NotFound />}>
      <div class={styles.article}>
        {props.children}
        <Show when={page()}>
          <p class={styles.page}>
            Página {(page() + 1).toLocaleString(import.meta.env.VITE_LOCALE)} de{' '}
            {maxPage().toLocaleString(import.meta.env.VITE_LOCALE)}
          </p>
        </Show>
        <ul
          classList={{
            [rootStyles['clean-list']]: true,
            [styles['post-list']]: true,
          }}
        >
          <For each={data().posts}>
            {post => (
              <li>
                <PostLinkItem
                  title={post.title}
                  lead={post.lead}
                  slug={post.slug ?? ''}
                  sticky={post.sticky}
                  categoryTitle={post.category?.title ?? ''}
                  categorySlug={post.category?.slug ?? ''}
                  publishedAt={post.publishedAt}
                  cover={post.cover}
                  level="h2"
                />
              </li>
            )}
          </For>
        </ul>
        <Pages page={page()} total={maxPage()} />
      </div>
    </Show>
  );
}
